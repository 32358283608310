import React, { useEffect, useState } from 'react';
import { SafeAreaView, Alert, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Linking } from 'react-native';

const App = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user data when component mounts
    fetchUserData();
    // Fetch collab data when component mounts
    fetchCollabData();
  }, []);

  const fetchUserData = () => {
    // Make a request to your PHP file to retrieve user data based on session
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        // Update the state with the retrieved user data
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchCollabData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendar.php', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setCollabs([]);
        } else {
          setCollabs(data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleCardPress = (collabID) => {
    navigation.navigate('SingleCollabMetrics', { CollabID: collabID });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${hours}:${minutes}${ampm}`;
    const daysFromToday = Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24));
    const dayCountText = daysFromToday === 0 ? 'today' : daysFromToday > 0 ? `in ${daysFromToday} days` : `${Math.abs(daysFromToday)} days ago`;
    return `${formattedDate} (${dayCountText})`;
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View>
          <View style={styles.bannerContainer}>
            <Image
              style={styles.bannerImage}
              source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
              resizeMode="contain"
            />
          </View>
          
          <View style={styles.bottomBackground}>
            {userData.Username ? (
              <View style={styles.header}>
                <Text style={styles.headerText}>Hi, {userData.Username}</Text>
              </View>
            ) : (
              <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
                  <Text style={styles.headerText}>Log in</Text>
                </TouchableOpacity> 
              </View>
            )}

            {/* Dashboard Section */}
            <View style={styles.section}>
              <View style={styles.cardRow}>
                <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('BrandListingsDashboard')}>
                  <View style={styles.cornerTopLeft} />
                  <View style={styles.cornerTopRight} />
                  <View style={styles.cornerBottomLeft} />
                  <View style={styles.cornerBottomRight} />
                  <Ionicons name="laptop" size={35} color="white" />
                  <Text style={styles.cardTitle}>Campaigns</Text>
                  <Text style={styles.cardSubText}>Go to Dashboard</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.card} onPress={handleQRCodeScan}>
                  <Ionicons name="scan" size={35} color="white" />
                  <Text style={styles.cardTitle}>QR Scan</Text>
                  <Text style={styles.cardSubText}>Open Scanner</Text>
                </TouchableOpacity>
              </View>
              {/* Add more rows as needed */}
            </View>

            {/* Upcoming Collabs Section */}

            {userData.Username && (
  <>
    <Text style={styles.sectionHeader}>Collab Calendar</Text>
    <View style={styles.section}>
      {loading ? (
        <Text></Text>
      ) : (
        collabs.length === 0 ? (
          <Text style={styles.noCollabText}>No Upcoming Collabs</Text>
        ) : (
          collabs.slice(0, 3).map((collab, index) => (
            <TouchableOpacity key={index} style={styles.collabCard} onPress={() => handleCardPress(collab.CollabID)}>
              <Ionicons name="calendar" size={24} color="black" style={styles.icon} />
              <Text style={styles.collabCardTitle}>{formatDate(collab.CreditAvailableStart)}</Text>
              <Text style={styles.collabCardSubTitle}>Status: {collab.Status}</Text>
              <Text style={styles.collabCardSubTitle}>Credits: £{collab.Credits}</Text>
              <Text style={styles.collabCardSubTitle}>Promoter: {collab.Promoter}</Text>
            </TouchableOpacity>
          ))
        )
      )}
    </View>
  </>
)}
          </View>
        </View>

        <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}> 
          <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy and Terms of Use. </Text> 
        </TouchableOpacity> 
      </ScrollView>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        
        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BrandListingsDashboard')}>
          <Ionicons name="laptop" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
    marginTop: '1%',
  },
  section: {
    marginBottom: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: 10,
    alignSelf: 'center',
    padding:10,
  },
  loginText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 15,
  },
  card: {
    width: '45%',
    height: 150,
    backgroundColor: 'black',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white',
    textAlign: 'center',
  },
  cardSubText: {
    fontSize: 12,
    textAlign: 'center',
    color: 'white',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  cornerTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    borderTopLeftRadius: 10,
  },
  cornerTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 10,
    borderTopRightRadius: 10,
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    borderBottomLeftRadius: 10,
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 10,
    height: 10,
    borderBottomRightRadius: 10,
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingBottom: 60, // Adjusted for the footer height
  },
  linkText: { 
    fontSize: 9, 
    color: 'black', 
    textDecorationLine: 'underline', 
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  }, 
  sectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: 10,
    alignSelf: 'center',
  },
  collabCard: {
    width: '90%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
    alignSelf: 'center',
  },
  collabCardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  collabCardSubTitle: {
    fontSize: 12,
    color: 'black',
  },
  noCollabText: {
    fontSize: 9,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
  },

});

export default App;
