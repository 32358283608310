import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, TouchableOpacity, Image, FlatList, TextInput, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg'; // Import QRCode component

const OfferAssociatedCollabs = ({ route, navigation }) => {
  const { ListingID } = route.params;
  const [collabs, setCollabs] = useState([]);
  const [filteredCollabs, setFilteredCollabs] = useState([]);
  const [listingViews, setListingViews] = useState({});
  const [listingVisits, setListingVisits] = useState({});
  const [visitPercentage, setVisitPercentage] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingAssociatedCollabs.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => {
        setCollabs(data);
        setFilteredCollabs(data);
      })
      .catch(error => console.error('Error fetching collabs:', error));

    fetchListingViews();
    fetchListingVisits();
  }, []);

  useEffect(() => {
    if (listingViews.totalViews && listingVisits.totalCount) {
      const percentage = (listingVisits.totalCount / listingViews.totalViews) * 100;
      setVisitPercentage(percentage.toFixed(2));
    }
  }, [listingViews.totalViews, listingVisits.totalCount]);

  useEffect(() => {
    if (searchText) {
      setFilteredCollabs(
        collabs.filter(collab =>
          collab.Promoter.toLowerCase().includes(searchText.toLowerCase()) ||
          collab.AdHeader.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredCollabs(collabs);
    }
  }, [searchText, collabs]);

  useEffect(() => {
    document.title = 'Listing Associated Collabs';
  }, []);

  const fetchListingViews = () => {
    fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingViews.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => setListingViews(data))
      .catch(error => console.error('Error fetching listing views:', error));
  };

  const fetchListingVisits = () => {
    fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingConversions.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => setListingVisits(data))
      .catch(error => console.error('Error fetching listing visits:', error));
  };

  const formatDate = (dateString) => {
    const dateParts = dateString.split(' ');
    const day = dateParts[0];
    const month = dateParts[1];
    
    const date = new Date(`${month} ${day}, ${new Date().getFullYear()}`);
    const currentYear = new Date().getFullYear();

    // Check if year is needed
    const year = date.getFullYear() !== currentYear ? `, ${date.getFullYear()}` : '';
    return `${day} ${month}${year}`;
  };

  const renderCollabItem = ({ item }) => (
    <TouchableOpacity style={styles.collabsCard} onPress={() => navigation.navigate('SingleCollabMetrics', { CollabID: item.CollabID })}>
      <Text style={styles.collabsCardIDText}>ID {item.CollabID}</Text>
      <Text style={styles.collabsCardIDTextPromoter}>{item.Promoter} on {item.Date}</Text>
      <Text style={styles.collabsCardIDText}>Campaign: {item.AdHeader}</Text> {/* Display AdHeader */}
    </TouchableOpacity>
  );

  const renderFinanceSymbol = (label, value) => {
    let symbol = null;
    if (value > 0) {
      symbol = <Ionicons name="arrow-up" size={24} color="green" />;
    } else if (value === 0) {
      symbol = <Ionicons name="remove" size={24} color="yellow" />;
    } else {
      symbol = <Ionicons name="arrow-down" size={24} color="red" />;
    }

    return (
      <View style={styles.barContainer}>
        <Text style={styles.barLabel}>{label}</Text>
        {symbol}
        <Text style={styles.barValue}>{value}</Text>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        
        <Text style={styles.idText}>Offer ID {ListingID}</Text>
      </View>

      {/* QR Code Section */}
      <View style={styles.qrCodeContainer}>
        <Image source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }} style={styles.logo} />
        <QRCode
          value={`https://collabbay.com/collaboffer?ListingID=${ListingID}`} // Example URL or data to encode
          size={250} // Adjust size as needed
        />
        <Text style={styles.ctaqrText}>Present, share or print to allow users to collab.</Text>

        {/* New Button for Save and Print QR */}
        <TouchableOpacity 
          style={styles.savePrintButton} 
          onPress={() => Linking.openURL(`https://collabbayadmin.com/ListingQRPDFImage.html?ListingID=${ListingID}`)}
        >
          <Ionicons name="download" size={16} color="white" />
          <Text style={styles.savePrintButtonText}> Save and Print QR</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.analyticsContainer}>
        <View style={styles.analyticsCard}>
          <Text style={styles.analyticsTitle}>Views</Text>
          {renderFinanceSymbol('Today', listingViews.viewsToday)}
          {renderFinanceSymbol('Last 7 Days', listingViews.viewsLast7Days)}
          {renderFinanceSymbol('Last 30 Days', listingViews.viewsLast30Days)}
        </View>

        <View style={styles.analyticsCard}>
          <Text style={styles.analyticsTitle}>Visits</Text>
          {renderFinanceSymbol('Today', listingVisits.countToday)}
          {renderFinanceSymbol('Last 7 Days', listingVisits.countLast7Days)}
          {renderFinanceSymbol('Last 30 Days', listingVisits.countLast30Days)}
        </View>
      </View>

      <View style={styles.searchContainer}>
        <Ionicons name="search" size={24} color="#999" style={styles.searchIcon} />
        <TextInput
          style={styles.searchInput}
          placeholder="Find a collab"
          value={searchText}
          onChangeText={setSearchText}
        />
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {`${filteredCollabs.length} Collabs`}
        </Text>
        <FlatList
          data={filteredCollabs}
          renderItem={renderCollabItem}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('QRCodeScannerPage')}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BrandListingsDashboard')}>
          <Ionicons name="laptop" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  idText: {
    fontSize: 12,
    padding: 5,
  },
  analyticsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  analyticsCard: {
    flex: 1,
    backgroundColor: '#000',
    borderRadius: 10,
    padding: 10,
    marginHorizontal: 11,
  },
  analyticsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#FDF05D',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#f0f0f0',
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  searchIcon: {
    marginRight: 10,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
  },
  section: {
    flex: 1,
    paddingHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'black',
  },
  collabsCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    borderColor: '#ddd',
    borderWidth: 1,
    padding: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  collabsCardIDText: {
    fontSize: 11,
    padding: 5,
    color: 'black',
  },
  collabsCardIDTextPromoter: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: 5,
    color: 'black',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  logo: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
    marginBottom: 10,
    borderRadius: 10,
  },
  ctaqrText: {
    fontSize: 14,
    padding: 5,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    width: '80%',
  },
  savePrintButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#000', // Button color
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 10,
  },
  savePrintButtonText: {
    color: 'white', // Text color
    marginLeft: 5,
  },
  barContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  barLabel: {
    width: 100,
    marginRight: 10,
    color: '#FFF',
  },
  barValue: {
    marginLeft: 10,
    color: '#FFF',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
});

export default OfferAssociatedCollabs;
