import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, TextInput, TouchableOpacity, Alert, Image, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; // Assuming FontAwesome for icons
import { useNavigation } from '@react-navigation/native'; // Import the navigation hook

const API_BASE_URL = 'https://collabbayadmin.com/APIControllers/';

const YourComponent = ({ route }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Form state
  const [formValues, setFormValues] = useState({
    CollabID: '',
    Credits: '',
    Promoter: '',
    BrandName: '',
    SecretQRID: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}TestAdminRedeemPresentedQR.php?QRID=${route.params.QRID}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch QR data');
        }
        const qrData = await response.json();
        setData(qrData);

        // Fetch credits data using CollabID from first API response
        const creditValidatorResponse = await fetch(
          `${API_BASE_URL}CreditValidator.php?CollabID=${qrData.CollabID}`
        );
        if (!creditValidatorResponse.ok) {
          throw new Error('Failed to fetch credit validator data');
        }
        const creditValidatorData = await creditValidatorResponse.json();
        
        // Update formValues with fetched data
        setFormValues({
          CollabID: qrData.CollabID,
          Credits: creditValidatorData.final_value.toString(),
          Promoter: qrData.Promoter,
          BrandName: qrData.BrandName,
          SecretQRID: qrData.SecretQRID
        });

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [route.params.QRID]);

  const handleInputChange = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value
    });
  };
  const handleSubmit = () => {
    // Assuming qrData is defined somewhere in your component
  
    fetch(`${API_BASE_URL}InsertCreditRedeem.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    })
      .then(response => response.json())
      .then(result => {
        console.log('Insertion result:', result);
        if (result.success) {
          Alert.alert("Success", "Data inserted successfully!");
  
          // Navigate to SingleCollabMetrics screen with CollabID as qrData.CollabID
          const navigation = useNavigation(); // Hook into navigation
  
          navigation.navigate('SingleCollabMetrics', {
            CollabID: qrData.CollabID,
          });
        } else {
          Alert.alert("Error", "Failed to insert data. Please try again.");
        }
      })
      .catch(error => {
        console.error('Error inserting data:', error);
        Alert.alert("Error", "An error occurred. Please try again.");
      });
  };

  if (error) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text>Error: {error}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {/* Page Header */}
      <Text style={styles.header}>Redeem {formValues.Promoter}'s Credits</Text>

      {/* Logo */}
      <View style={styles.logoContainer}>
        <Image
          style={styles.logoImage}
          source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
          resizeMode="contain"
        />
      </View>

      {/* Credits Display */}
      <View style={styles.creditsContainer}>
        <Text style={styles.creditsText}>£{formValues.Credits}</Text>
      </View>

      {/* Yellow Card for Promoter x BrandName */}
      <View style={styles.yellowCard}>
        <Text style={styles.yellowCardText}>{formValues.Promoter} x {formValues.BrandName}</Text>
      </View>

      {/* Form for inserting data */}
      <TextInput
        style={styles.input}
        placeholder="Promoter"
        value={formValues.Promoter}
        onChangeText={text => handleInputChange('Promoter', text)}
        editable={false} // Prevent editing since it's fetched data
      />
      <TextInput
        style={styles.input}
        placeholder="BrandName"
        value={formValues.BrandName}
        onChangeText={text => handleInputChange('BrandName', text)}
        editable={false} // Prevent editing since it's fetched data
      />
      <TextInput
        style={[styles.input, styles.collabIDInput]} // Adjusted style for CollabID
        placeholder="CollabID"
        value={formValues.CollabID}
        onChangeText={text => handleInputChange('CollabID', text)}
        editable={false} // Prevent editing since it's fetched data
      />

      <TouchableOpacity style={styles.button} onPress={handleSubmit}>
        <Text style={styles.buttonText}>Redeem Promoter Credits</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('SingleCollabMetrics')}>
  <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', marginTop: 15, marginBottom: 15 }}>
    See Collab Details
  </Text>
</TouchableOpacity>


    </View>



  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 20,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  logoContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  logoImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  creditsContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  creditsText: {
    fontSize: 45,
    fontWeight: 'bold',
    color: '#333',
  },
  yellowCard: {
    backgroundColor: '#FDF05D',
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',
  },
  yellowCardText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  input: {
    height: 50,
    width: '100%',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 10,
    paddingHorizontal: 10,
    marginBottom: 10,
    fontSize: 16,
    color: '#000',
  },
  collabIDInput: {
    fontSize: 14,
  },
  button: {
    backgroundColor: 'black',
    padding: 15,
    marginTop: 20,
    borderRadius: 10,
    width: '100%',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default YourComponent;
