import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TextInput, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const PotentialCollabsDashboardView = ({ navigation }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchData();
    fetchUserData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/RetrieveBrandListings.php');
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleSearchChange = (text) => {
    setSearchQuery(text);
  };

  const filteredData = data.filter(item =>
    item.AdHeader.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const suffix = day % 10 === 1 && day !== 11 ? 'st' :
                   day % 10 === 2 && day !== 12 ? 'nd' :
                   day % 10 === 3 && day !== 13 ? 'rd' : 'th';

    const formattedDate = `${day}${suffix} ${month}`;
    return year !== now.getFullYear() ? `${formattedDate}, ${year}` : formattedDate;
  };

  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>

      <View style={styles.searchBar}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search my campaigns"
          placeholderTextColor="grey"
          value={searchQuery}
          onChangeText={handleSearchChange}
        />
      </View>

      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.section}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>My Influencer Promotion Offers</Text>
          </View>

          {filteredData.map((item, index) => (
            <TouchableOpacity 
              style={styles.card} 
              key={index} 
              onPress={() => navigation.navigate('ListingAssociatedCollabsDashboard', { ListingID: item.ListingID })}
            >
              <View style={styles.cardContent}>
                <Image style={styles.cardLogo} source={{ uri: item.LogoImage }} />
                <View style={styles.cardTextContainer}>
                  <Text style={styles.cardSubText}>Created {formatDate(item.Created_At)}</Text>
                  <Text style={styles.cardTitle}>{item.AdHeader}</Text>
                  <Text style={styles.cardSubText}>{item.ListingDescription}</Text>
                  <Text style={styles.cardSubLocationText}>{item.Location}</Text>
                </View>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  searchBar: {
    marginTop: 70, // Adjust based on your needs
    paddingHorizontal: 20,
  },
  searchInput: {
    backgroundColor: '#e0e0e0',
    color: 'black',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 20,
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    marginTop: 20,
  },
  section: {
    marginBottom: 20,
    width: '100%',
  },
  sectionTitleContainer: {
    backgroundColor: '#FDF05D',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  card: {
    width: '100%',
    minWidth: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 3,
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardLogo: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 15,
    resizeMode: 'cover',
  },
  cardTextContainer: {
    flex: 1,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  cardSubText: {
    fontSize: 12,
    color: 'black',
    padding: 5,
  },
  cardSubLocationText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
});

export default PotentialCollabsDashboardView;
