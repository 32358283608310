import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { useParams } from 'react-router-dom';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';

const PostScanAdminRedeemCustomer = () => {
  const { CollabID } = useParams();
  const [data, setData] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (CollabID) {
      fetch(`https://collabbayadmin.com/APIControllers/RetrieveCustomerQRRedeem.php?CollabID=${CollabID}`)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            setData(result[0]);
          } else {
            setMessage('No records found');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setMessage('An error occurred while fetching data');
        });
    }
  }, [CollabID]);

  const handleSubmit = async () => {
    if (data) {
      const formData = new FormData();
      formData.append('CollabID', data.CollabID);
      formData.append('BrandName', data.BrandName);
      formData.append('Promoter', data.Promoter);
      formData.append('Redeem_Message', data.Redeem_Message);
      formData.append('ListingID', data.ListingID);

      try {
        const response = await fetch('https://collabbayadmin.com/APIControllers/InsertCustomerQRRedeem.php', {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();

        if (response.ok && result.status === 'Data inserted successfully') {
          setMessage('Visit Successfully Logged');
          setTimeout(() => {
            window.location.href = 'https://collabbayadmin.com/';
          }, 1000); // Redirect after 1 second to allow the message to be seen
        } else {
          setMessage(result.status || 'Oops something went wrong, try refreshing the browser');
        }
      } catch (error) {
        console.error('Error inserting data:', error);
        setMessage('An error occurred while inserting data');
      }
    } else {
      setMessage('No data to submit');
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {data ? (
          <View style={styles.card}>
            <Text style={styles.title}>Customer Visit Information</Text>
            <View style={styles.infoContainer}>
              <MaterialIcons name="qr-code" size={24} color="black" />
              <Text style={styles.infoText}>CollabID: {data.CollabID}</Text>
            </View>
            <View style={styles.infoContainer}>
              <MaterialIcons name="local-offer" size={24} color="black" />
              <Text style={styles.infoText}>BrandName: {data.BrandName}</Text>
            </View>
            <View style={styles.infoContainer}>
              <MaterialIcons name="person" size={24} color="black" />
              <Text style={styles.infoText}>Promoter: {data.Promoter}</Text>
            </View>
            <View style={styles.infoContainer}>
              <MaterialIcons name="message" size={24} color="black" />
              <Text style={styles.infoText}>Redeem Message: {data.Redeem_Message}</Text>
            </View>
            <View style={styles.infoContainer}>
              <MaterialIcons name="list" size={24} color="black" />
              <Text style={styles.infoText}>ListingID: {data.ListingID}</Text>
            </View>
            <TouchableOpacity style={styles.button} onPress={handleSubmit}>
              <FontAwesome name="check-circle" size={20} color="black" />
              <Text style={styles.buttonText}>Redeem and Log Visit</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <Text style={styles.text}>{message}</Text>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    alignItems: 'center',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    marginBottom: 20,
    width: '100%',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoText: {
    fontSize: 16,
    marginLeft: 10,
    color: '#333',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontSize: 16,
    marginLeft: 10,
  },
  text: {
    color: '#000000',
    fontSize: 16,
    marginVertical: 5,
  },
});

export default PostScanAdminRedeemCustomer;
