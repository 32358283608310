import React from 'react';
import { Alert, View, Text, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Feather from 'react-native-vector-icons/Feather';

const AccountSettingsPage = ({ navigation }) => {



  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/EndSession.php');
      const data = await response.json();
  
      // Display logout status message
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
      } else {
        Alert.alert('Logout', 'No active session found');
      }
      // Reload the app after logout
      window.location.reload(); // This will refresh the app
  
    } catch (error) {
      console.error('Error:', error);
      // Handle error if fetch or navigation fails
      // Optionally, show an alert or log the error
    }
  };


  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={() => navigation.navigate('BusinessHomepage')}>
        <Feather name="home" size={24} color="#000" />
      </TouchableOpacity>

      <Text style={styles.header}>Account Settings</Text>

      <TouchableOpacity style={styles.settingContainer}>
        <Ionicons name="person-outline" size={24} color="black" />
        <Text style={styles.settingText}>Edit Profile</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.settingContainer}>
        <Ionicons name="notifications-outline" size={24} color="black" />
        <Text style={styles.settingText}>Help</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.settingContainer} onPress={() => navigation.navigate('PaymentPage')}>
        <Ionicons name="card-outline" size={24} color="black" />
        <Text style={styles.settingText}>Billing</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
        <Text style={styles.logoutText}>Log Out</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}> 
<Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy. </Text> 
</TouchableOpacity> 

    </View>

    
  );
};



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#333333',
  },
  settingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#F5F5F5',
    borderRadius: 15,
    width: '100%',
  },
  settingText: {
    marginLeft: 20,
    fontSize: 18,
    color: '#333333',
  },
  logoutButton: {
    backgroundColor: 'black',
    paddingVertical: 15,
    paddingHorizontal: 50,
    borderRadius: 15,
    marginTop: 30,
  },
  logoutText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
  },
  backButton: {
    position: 'absolute',
    top: '10%',
    margin: 'auto',
    zIndex: 1,
  },
  linkText: { 
    fontSize: 9, 
    color: 'black', 
    textDecorationLine: 'underline', 
    marginTop: 25,

  }, 
});

export default AccountSettingsPage;
