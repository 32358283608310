import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Alert, ScrollView, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function App({ navigation }) {
  // State variables to manage form data
  const [location, setLocation] = useState('');
  const [listingDescription, setListingDescription] = useState('');
  const [redeemMessage, setRedeemMessage] = useState('');
  const [adHeader, setAdHeader] = useState('');
  const [brandURL, setBrandURL] = useState('');
  const [credits, setCredits] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [preferredAvailability, setPreferredAvailability] = useState('');

  const handleBackPress = () => {
    navigation.goBack();
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    if (credits < 0 || credits > 10000) {
      Alert.alert('Invalid Credits', 'Credits must be between 0 and 10,000.');
      return;
    }

    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/InsertCreatedBrandListing.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Location: location,
          ListingDescription: listingDescription,
          Redeem_Message: redeemMessage,
          AdHeader: adHeader,
          BrandURL: brandURL,
          Credits: credits,
          FullAddress: fullAddress,
          PreferredAvailability: preferredAvailability,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        const listingID = result.ListingID;
        const url = `https://collabbayadmin.com/ListingImageUpload.html?ListingID=${encodeURIComponent(listingID)}`;
        Linking.openURL(url);
      } else {
        Alert.alert('Error', result.error);
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while submitting the form.');
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>

      <Text style={styles.header}>Post your offer to influencers</Text>
      <Text style={styles.subHeader}>Set up your collab bay with offer terms</Text>

      <View style={styles.form}>
        <View style={styles.inputContainer}>
          <Ionicons name="location-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Location"
            value={location}
            onChangeText={setLocation}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="text-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Listing Description"
            value={listingDescription}
            onChangeText={setListingDescription}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="chatbubble-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Redeem Message"
            value={redeemMessage}
            onChangeText={setRedeemMessage}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="newspaper-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Ad Header"
            value={adHeader}
            onChangeText={setAdHeader}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="link-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Brand URL"
            value={brandURL}
            onChangeText={setBrandURL}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="pricetag-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Credits"
            value={credits}
            onChangeText={(text) => setCredits(text.replace(/[^0-9]/g, '').slice(0, 5))}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="home-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Full Address"
            value={fullAddress}
            onChangeText={setFullAddress}
          />
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="calendar-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Preferred Availability"
            value={preferredAvailability}
            onChangeText={setPreferredAvailability}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
          <Text style={styles.buttonText}>Post</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 20,
    justifyContent: 'center',
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    color: 'black',
  },
  subHeader: {
    fontSize: 16,
    textAlign: 'center',
    color: 'grey',
    marginBottom: 20,
  },
  form: {
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    height: 40,
    borderRadius: 20,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'lightgrey',
  },
  button: {
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
});
